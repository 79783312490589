import React from 'react'
import Head from '../components/head'
import '../styles/index.scss'
import FadeIn from 'react-fade-in';

const indexPage = () => {
  return (
    <FadeIn>
      <Head/>
        <div id="uhoh-wrapper">
            <a href="/" rel="noreferrer">
                <h3 id="uhoh-text">BACK TO JCR.NYC</h3>                
            </a>
        </div>
    </FadeIn>
  )
}

export default indexPage